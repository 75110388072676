import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { Button, Grid, Icon, Segment } from 'semantic-ui-react';
import { Checkbox, Input, Select, SweetForm } from 'sweetform';
import _ from 'underscore';
import baseUrl from '../baseUrl.js';

const initialValue = {};

const getQueryResult = async (query) => {
  const response = (await axios.post(`${baseUrl}/candidates/exportCSV`, query)).data;
  if (!response.success) {
    throw Error(JSON.stringify(response.error));
  }
  return response.results;
};

const getLastWeekEnabledCandidates = async () => {
  const response = (await axios.post(`${baseUrl}/candidates/exportLastWeekEnabledCandidatesCsv`, { weeksAgo: 1 })).data;
  if (!response.success) {
    throw Error(JSON.stringify(response.error));
  }
  return response.results;
};

const downloadAsCsv = async ({ items, prefix }) => {
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here

  let header = [];
  _.each(items, (item) => {
    header = _.uniq(_.union(header, _.keys(item)));
  });

  let csv = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  // Download csv
  var csvData = new Blob([csv], { type: 'text/csv' });
  var csvUrl = URL.createObjectURL(csvData);
  var now = moment().format('YYMMDDHHmmss');
  var hiddenElement = document.createElement('a');
  hiddenElement.href = csvUrl;
  hiddenElement.download = `${prefix}_${now}.csv`;
  hiddenElement.click();
};

const discoverLinkFromProfileId = (profileId) => {
  const searchPoolId = 'watch';
  const spId = encodeURIComponent(btoa(searchPoolId));
  const sppId = encodeURIComponent(btoa(profileId));
  const querystring = `?spId=${spId}&sppId=${sppId}`;
  return `https://app.hiresweet.com/redirect/discover/candidates${querystring}`;
};

class WatchExportCSV extends React.Component {
  state = {};

  componentWillMount() {
    this.setState({
      query: initialValue,
    });
  }

  handleChangeQuery = (value) => {
    this.setState({
      query: value,
    });
  };

  handleSubmitQuery = async () => {
    try {
      this.setState({ computing: true });
      const query = this.state.query;
      const results = await getQueryResult(query);

      await downloadAsCsv({
        items: _.map(results, (result) => ({ link: discoverLinkFromProfileId(result.id), ...result })),
        prefix: 'export',
      });
      this.setState({
        computing: false,
      });
    } catch (e) {
      alert('Error getting query results : ' + JSON.stringify(e.message));
      this.setState({
        computing: false,
      });
    }
  };

  handleFetchLastWeek = async () => {
    try {
      this.setState({ computing: true });

      const results = await getLastWeekEnabledCandidates();

      await downloadAsCsv({
        items: _.map(results, (result) => ({ link: discoverLinkFromProfileId(result.id), ...result })),
        prefix: 'export',
      });
      this.setState({
        computing: false,
      });
    } catch (e) {
      alert('Error getting query results : ' + JSON.stringify(e.message));
      this.setState({
        computing: false,
      });
    }
  };

  render() {
    const { computing } = this.state;
    return (
      <Grid columns={2}>
        <Grid.Column width={4}>
          <Segment>
            <SweetForm initialValues={initialValue} onChange={this.handleChangeQuery}>
              <b>Min creation date:</b>
              <Input type='date' field='minCreationDate' />
              <b>Max creation date:</b>
              <Input type='date' field='maxCreationDate' />
              <b>Status:</b>
              <Select
                multi
                options={[
                  { label: 'Enabled', value: 'enabled' },
                  { label: 'Hold', value: 'hold' },
                  { label: 'Disabled', value: 'disabled' },
                ]}
                field='status'
              />
              <b>Top candidate:</b>
              <Checkbox field='isTopCandidate' />
              <br />

              {computing ? (
                <Icon name='circle notched' loading />
              ) : (
                <Button color='green' onClick={() => this.handleSubmitQuery()}>
                  Download CSV
                </Button>
              )}
            </SweetForm>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          {computing ? (
            <Icon name='circle notched' loading />
          ) : (
            <Button color='green' onClick={() => this.handleFetchLastWeek()}>
              Download CSV with Last Week Enabled Candidates
            </Button>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

export default WatchExportCSV;
